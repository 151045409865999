import React, {Component} from "react";


export default class PersonalPage extends Component {

  render() {
    return (
      <div className="bg-deep-blue h-screen"></div>
    )
  }
}