import React, { Component } from "react";

export default class TextZone extends Component {
  render() {
    return (
      <div className="flex flex-col">

        <div className="font-monospace text-white text-xl text-center mt-2">

          I ❤️ coding stuff.
        </div>
      </div>
    );
  }
}
